import React from "react";
import dash from "../img/dashed.png"
import AboutArray from "../Arrays/AboutArray";



import Modal from 'react-bootstrap/Modal';
import { useState } from 'react'

const About = () => {
    const [modalShow, setModalShow] = useState(false);
    const [imgVal, setimgVal] = useState("")
    const [imgsize, setimgsize] = useState("")

    const imgArry = AboutArray.map(media => media.img);
    const sizeArry = AboutArray.map(media => media.size);

    function Nextbtn(img) {
        let arryIndex = imgArry.indexOf(img)+1
        if (arryIndex === imgArry.length){
            arryIndex = 0
        }
        setimgsize(sizeArry[arryIndex])
        setimgVal(imgArry[arryIndex])
    }
    function Prevbtn(img) {
        let arryIndex = imgArry.indexOf(img)-1
        if (arryIndex === -1){
            arryIndex = imgArry.length-1
        }
        setimgsize(sizeArry[arryIndex])
        setimgVal(imgArry[arryIndex])
    }

    const MyVerticallyCenteredModal = (props) =>{
        return (
          <Modal  {...props} size={props.size}  aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
            <button type="button" className="btn-close btn-close-dark position-absolute end-0 top-0 rounded-circle " onClick={props.onHide}>  </button>
            <img src={props.img} style={{width:"100%", height:"100%"}} className='p-2' alt="" />

            <button className="carousel-control-prev h-50 my-auto" type="button" onClick={() => Prevbtn(props.img)}>
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next h-50 my-auto" type="button" onClick={() => Nextbtn(props.img)}>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
            </Modal.Body>
          </Modal>
        );
      }

    const ImgElement = (props) => {
    return(
        <>
            <span className={`${props.position} cursor-pointer`} >
                <img src={props.img} alt='' className="media" onClick={() => {setModalShow(true); setimgVal(props.img);setimgsize(props.size)}}/>
            </span>
        </>
    )
    }
    return(
        <>
        <div className="container mt-5" id="About">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2 className="Headers mb-0 fs-lg-3 fs-md-3 fs-sm-2">About Wheelchair Cricket</h2>
                    <img src={dash}className="mx-auto" alt=""/>
                </div>
                <div className="row">
                    <div className="col-md-7 my-auto">
                      <p className="text-justify paragraph">Mumbai city is known as the cricket capital of India, Mumbai has set records by winning the prestigious Ranji Trophy 41 times. Mumbai has produced great cricketers like Ajit Wadekar, Sunil Gavaskar, Sachin Tendulkar, Rohit Sharma & many more who contributed to Indian cricket. Even in the Indian Premier League, Mumbai Indians have won more championships than any other team. </p>
                      <p className="text-justify paragraph">Unfortunately, the city does not have its own Wheelchair Cricket Team for wheelchair cricketers of Mumbai & to change this Wheelchair Sports Association Mumbai has taken an initiative & formed the Mumbai Wheelchair Cricket Team. The Mumbai team will provide more opportunities to wheelchair cricketers across Mumbai & Maharashtra to play at the national & international levels.  </p>
                      <p className="text-justify paragraph">Wheelchair cricketers are 70% to 90% physically challenged, they have major disabilities in their lower body. They do batting, bowling & fielding on wheelchairs. The team includes players with different disabilities like polio, spinal cord injuries, cerebral palsy & even amputees. Mumbai Wheelchair Cricket Team is managed by Wheelchair Sports Association Mumbai and it does not have any support from Mumbai Cricket Association (MCA), BCCI, or any other government body. Wheelchair Sports Association Mumbai is registered under the Societies Act, 1860 & it is affiliated with Wheelchair Cricket India Association which manages the Indian Wheelchair Cricket Team.</p>
                      {/* <button className="btn btn-success bg-gradient shadow" >Read More</button> */}
                    </div>
                    <div className="col-md-5 my-auto" >
                    <div className="media-container">
                      <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} img ={imgVal} size={imgsize} />
                      {AboutArray.map((value,index)=>{                            
                          return(
                              <ImgElement img={value.img} key={index} size={value.size} position={value.position}/>
                          )
                      })}
                    </div>
                </div>
                </div>
            </div>
        </div>
        
        
        </>
    )
}

export default About