import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import pdfUrl from '../img/PDFData/CSR.PDF'
import pdfG from '../img/PDFData/CSR1.PDF'
import pdfThumb1 from '../img/PDFData/pdfImage.png'
import pdfThumb2 from '../img/PDFData/pdfImage2.png'
import dash from '../img/dashed.png'
import registrationPdf from '../img/PDFData/RegCirtificate.pdf'
import regimg from '../img/PDFData/pdfImage3.png'

const PdfCard = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [showModal1, setShowModal1] = useState(false);

  const handleClose1 = () => setShowModal1(false);
  const handleShow1 = () => setShowModal1(true);

  const [regModal, setregModal] = useState(false);

  const regClose = () => setregModal(false);
  const regeShow = () => setregModal(true);

  return (
    <>

      <div className="container d-flex justify-content-center mt-5" id="Document">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2 className="Headers mb-0 fs-lg-3 fs-md-3 fs-sm-2">Document Library</h2>
            <img src={dash} className="mx-auto" alt="" />
          </div>

          <div className="row  ms-1" >
            <div className="col-sm-4 cursor-pointer text-center mt-3" onClick={handleShow}>
              <div>
                <img src={pdfThumb1} alt="" className="doc-image" />
              </div>
              <div className='my-3'>
                <h4 className='PDF-header'>CSR1 Certificate</h4>
              </div>
            </div>

            <div className="col-sm-4 cursor-pointer text-center mt-3" onClick={regeShow}>
              <div>
                <img src={regimg} alt="" className="doc-image" />
              </div>
              <div className='my-3'>
                <h4 className='PDF-header'>Registration Certificate</h4>
              </div>
            </div>

            <div className="col-sm-4 cursor-pointer text-center mt-3" onClick={handleShow1}>
              <div>
                <img src={pdfThumb2} alt="" className="doc-image" />
              </div>
              <div className='my-3'>
                <h4 className='PDF-header'>80G Certificate</h4>
              </div>
            </div>
            
          </div>
        </div>
      </div>


      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>CSR1_Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <embed src={pdfUrl} width="100%" height="500px" />
        </Modal.Body>
      </Modal>

      <Modal show={showModal1} onHide={handleClose1} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>80G_Certificate </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <embed src={pdfG} width="100%" height="500px" />
        </Modal.Body>
      </Modal>

      <Modal show={regModal} onHide={regClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Registration_Certificate </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <embed src={registrationPdf} width="100%" height="500px" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PdfCard;
