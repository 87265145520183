import award1 from '../img/Awards/Award1.png'
import award2 from '../img/Awards/Award2.png'
import award3 from '../img/Awards/Award3.png'
import award4 from '../img/Awards/Award4.png'
import award5 from '../img/Awards/Award5.png'
import award6 from '../img/Awards/Award6.png'
import award7 from '../img/Awards/Award7.png'
import award8 from '../img/Awards/Award8.png'
import award9 from '../img/Awards/Award9.png'

const AwardsArray=[
    {
        img: award1,
        size:'lg',
        position:'big'
    },
    {
        img: award2,
        size:'md',
        position:'vertical'
    },
    {
        img: award9,
        size:'md',
        position:'vertical'
    },
    {
        img: award3,
        size:'lg',
        position:'big'
    },
    {
        img: award8,
        size:'xl',
        position:'horizontal'
    },
    {
        img: award5,
        size:'xl',
        position:'horizontal'
    },
    {
        img: award4,
        size:'md',
        position:'big'
    },
    {
        img: award6,
        size:'md',
        position:'horizontal'
    },
    {
        img: award7,
        size:'lg',
        position:'horizontal'
    },
    
]

export default AwardsArray;