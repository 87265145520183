import React from "react";
// import scr1 from "../img/pdf/CSR1_Certificate.PDF"
import dash from '../img/dashed.png'
// import video from '../img/Video.mp4'
import image from '../img/videotitle.PNG'
import playbtn from '../img/playbutton.png'
import videothumb from '../img/Videothumb1.png'
import videothumb2 from '../img/Videothumb2.png'
import videothumb3 from '../img/Videothumb3.png'

const Videos = () => {

    return(
        <div className="container d-flex justify-content-center mt-5" id= 'Videos'>
            <div className="row">
                <div className="col-md-12 text-center mt-5">
                    <h2 className="Headers mb-0 fs-lg-3 fs-md-3 fs-sm-2">Video Showcase</h2>
                    <img src={dash}className="mx-auto" alt=""/>
                </div>
            
                <div className="row ms-1" >
                    <div className="col-sm-3 video-container mt-3">
                        <div className="video">
                            <a href="https://youtu.be/U0JlzTCq6tE" target='blank' className="video-link">
                                <img src={playbtn} alt="" className="play-btn"  />
                                <img src={image} alt=""  className="video-image" />
                            </a>
                        </div>
                    </div>

                    <div className="col-sm-3 video-container mt-3">
                        <div className="video">
                            <a href="https://www.youtube.com/watch?v=LQf2u13hZf8" target='blank' className="video-link">
                                <img src={playbtn} alt="" className="play-btn"  />
                                <img src={videothumb2} alt=""  className="video-image" />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-3 video-container mt-3">
                        <div className="video">
                            <a href="https://www.youtube.com/watch?v=FkwO4Qp3az8" target='blank' className="video-link">
                                <img src={playbtn} alt="" className="play-btn"  />
                                <img src={videothumb} alt=""  className="video-image" />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-3 video-container mt-3">
                        <div className="video">
                            <a href="https://www.youtube.com/watch?v=J1ZrjpVE0J8" target='blank' className="video-link">
                                <img src={playbtn} alt="" className="play-btn"  />
                                <img src={videothumb3} alt=""  className="video-image" />
                            </a>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>

    )
  
}

export default Videos