import { useState } from "react";
import MainLogo from '../img/AssociationLogo.png'
import teamlogo from '../img/MainLogo.png'
import { HashLink } from 'react-router-hash-link';
import menuico from '../img/Menu.png'

const NavElements = () => {

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => { setIsNavCollapsed(!isNavCollapsed); }

    const [fixed, setfixed] = useState(false)

    function FixedNav() {
        if (window.scrollY >= 500) {
            setfixed(true)
        }
        else {
            setfixed(false)
        }
    }

    window.addEventListener("scroll", FixedNav)
    return (
        <>
            <div className={fixed ? " z-1  Nav-container fixed" : " z-1  Nav-container"}>
                <nav className="navbar navbar-expand-lg   z-5  wow fadeInDown " data-wow-duration="1s" data-wow-delay="0.5s" id='Navbar'>
                    <div className={fixed ? " nav fixed" : "nav"}>
                        <div className="d-flex justify-content-evenly align-items-center ps-2 pe-2">
                            <HashLink className='LogoImage ' to={'/'}>
                                <img className="main-logo p-2" src={teamlogo} alt="Association Logo" ></img>
                                <img className="main-logo" src={MainLogo} alt="Association Logo"></img>

                            </HashLink>

                            <div className=" navbar-toggle nav-tog-btn" >
                                <input className="mx-1 " type='image' src={menuico} name="facebook" alt="facebook" width={30} height={30}  onClick={handleNavCollapse} />
                            </div>

                            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse mx-auto`} id="navbarid" >
                                <ul className="navbar-nav ">
                                    <HashLink to="#About" className="nav-item mx-2 remove-decoreation "  >About</HashLink>
                                    <HashLink to="#WomanTeam" className="nav-item mx-2 remove-decoreation " >MWWCT</HashLink>
                                    <HashLink to="#Tournament" className="nav-item mx-2 remove-decoreation " >Tournaments</HashLink>
                                    <HashLink to="#Media" className="nav-item mx-2 remove-decoreation ">MediaCoverage</HashLink>
                                    <HashLink to="#Awards" className="nav-item mx-2 remove-decoreation " >Awards</HashLink>
                                    <HashLink to="#Videos" className="nav-item mx-2 remove-decoreation " >Videos</HashLink>
                                    <HashLink to="#Document" className="nav-item mx-2 remove-decoreation " >Documents</HashLink>
                                    <HashLink to="#Support" className="nav-item mx-2 remove-decoreation " >SupportUs</HashLink>
                                    <HashLink to="#Contact" className="nav-item mx-2 remove-decoreation " >ContactUs</HashLink>

                                </ul>
                            </div>
                        </div>



                    </div>
                </nav>
            </div>
            {/* <div className={fixed ? " z-1  Nav-container fixed" : " z-1  Nav-container"}>
            <nav className="navbar navbar-expand-lg   z-5  wow fadeInDown " data-wow-duration="1s" data-wow-delay="0.5s" id='Navbar'>
                <div className="nav">
                    <div className="d-flex justify-content-evenly align-items-center">
                            <HashLink className='LogoImage ' to={'/'}>
                                <img className="main-logo p-2" src={teamlogo} alt="Association Logo" style={{width:'13%'}}></img>
                                <img className="main-logo p-2" src={MainLogo} alt="Association Logo" style={{width:'18%'}}></img>
                                
                            </HashLink>
                            <button className="navbar-toggler bg-white bg-gradient" type="button"  onClick={handleNavCollapse} >
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse mx-auto`} id="navbarid">
                                <ul className="navbar-nav ">
                                    <HashLink to="#About" className="nav-item mx-2 remove-decoreation "  >About</HashLink>
                                    <HashLink to="#WomanTeam" className="nav-item mx-2  remove-decoreation" >MWWCT</HashLink>
                                    <HashLink to="#Tournament" className="nav-item mx-2  remove-decoreation" >Tournaments</HashLink>
                                    <HashLink to="#Media" className="nav-item mx-2 remove-decoreation " >MediaCoverage</HashLink>
                                    <HashLink to="#Awards" className="nav-item mx-2 remove-decoreation " >Awards</HashLink>
                                    <HashLink to="#Videos" className="nav-item mx-2  remove-decoreation" >Videos</HashLink>
                                    <HashLink to="#Document" className="nav-item mx-2 remove-decoreation " >Documents</HashLink>
                                    <HashLink to="#Contact" className="nav-item mx-2 remove-decoreation " >ContactUs</HashLink>
                                    
                                </ul>
                            </div>
                    </div>
                    
                    
                   
                </div>    
            </nav>
        </div> */}
        </>
    )
}

export default NavElements;