import tycsLogo from '../img/TYCS.png'

const Footer = () => {
    return(
        <>
            <div className="container-fluid mt-5 mb-3 " >
                <a href="http://tycs.in/" target='blank' style={{textDecoration:'none'}} >
                    <div className="d-flex justify-content-end p-3 rounded-3 footer">
                        <p className="my-auto footer-font">Design & Developed by</p>
                            <img src={tycsLogo} className="mx-2 footer-image" alt=""/>
                    </div>
                </a>
            </div>            
        </>
    )
}

export default Footer