import About from "./MainContain/About";
import Awards from "./MainContain/Awards";
import Background from "./MainContain/Background";
import CaraselImg from "./MainContain/CaraselImg";
import Media from "./MainContain/MediaCoverage";
import NavElements from "./MainContain/NavElements";
import Tournaments from "./MainContain/Tournaments";
import Contact from "./MainContain/contact";
import Footer from "./MainContain/footer";
import Videos from "./MainContain/Videos";
import PdfCard from "./MainContain/PdfElement";
import Woman from "./MainContain/Woman";
import Support from "./MainContain/SupportUs";
import Loader from './MainContain/Loader'

import { useState, useEffect } from "react";



const MainPage = () => {

    const [screenLoading, setScreenLoading] = useState(true);
    // useEffect(() => {
    //     setScreenLoading(true);
    //     setTimeout(() => {
    //         setScreenLoading(false);
    //     }, 3000);
    // }, []);

    // useEffect(() => {
    //     // Simulate fetching data with a delay
    //     const timer = setTimeout(() => {
    //         setScreenLoading(false); // Hide preloader after data is fetched
    //     }, 3000); // Adjust time as needed

    //     return () => clearTimeout(timer); // Cleanup the timer

    // }, []);

    useEffect(() => {
        // Function to be executed when the document is ready
        function yourFunction() {
            const timer = setTimeout(() => {
                setScreenLoading(false); // Hide preloader after data is fetched
            }, 3000);
            return () => clearTimeout(timer); 
        }

        // Check if the document is ready
        if (document.readyState === 'complete') {
            // If the document is already loaded, call the function immediately
            yourFunction();
        } else {
            // If the document is not yet loaded, add an event listener to execute the function when it is
            document.addEventListener('DOMContentLoaded', yourFunction);
        }

        // Cleanup function
        return () => {
            document.removeEventListener('DOMContentLoaded', yourFunction); // Remove the event listener on unmount
        };
    }, []);

    return (

        <>
            {screenLoading ? (
                <Loader />
            ) : (
                <>
                    <NavElements />
                    <CaraselImg />
                    <About />
                    <Woman />
                    <Tournaments />
                    <Awards />
                    <Media />
                    <Background />
                    <Videos />
                    <PdfCard />
                    <Support />
                    <Contact />
                    <Footer />
                </>
            )}
            {/* <NavElements />
            <CaraselImg />
            <About />
            <Woman />
            <Tournaments />
            <Awards />
            <Media />
            <Background />
            <Videos />
            <PdfCard />
            <Support />
            <Contact />

            <Footer />
             */}
        </>
    )
}

export default MainPage;