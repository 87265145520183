import dash from '../img/dashed.png'

import Modal from 'react-bootstrap/Modal';
import { useState } from 'react'


import AwardsArray from '../Arrays/AwardsArry';


const Awards = () => {
    const [modalShow, setModalShow] = useState(false);
    const [imgVal, setimgVal] = useState("")
    const [imgsize, setimgsize] = useState("")

    // const imgArry = [award1, award2, award9, award3,award8,award5,award4,award6,award7]
    // const sizeArry = ["lg","md",'md','lg','xl','xl','md','md','lg']
    const imgArry = AwardsArray.map(media => media.img);
    const sizeArry = AwardsArray.map(media => media.size);

    function Nextbtn(img) {
        let arryIndex = imgArry.indexOf(img)+1
        if (arryIndex === imgArry.length){
            arryIndex = 0
        }
        setimgsize(sizeArry[arryIndex])
        setimgVal(imgArry[arryIndex])
    }
    function Prevbtn(img) {
        let arryIndex = imgArry.indexOf(img)-1
        if (arryIndex === -1){
            arryIndex = imgArry.length-1
        }
        alert(sizeArry[arryIndex])
        setimgsize(sizeArry[arryIndex])
        setimgVal(imgArry[arryIndex])
    }

    const MyVerticallyCenteredModal = (props) =>{
        return (
          <Modal  {...props} size={props.size}  aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
            <button type="button" className="btn-close btn-close-dark position-absolute end-0 top-0 rounded-circle " onClick={props.onHide}>  </button>
            <img src={props.img} style={{width:"100%", height:"100%"}} className='p-2' alt="" />

            <button className="carousel-control-prev h-50 my-auto" type="button" onClick={() => Prevbtn(props.img)}>
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next h-50 my-auto" type="button" onClick={() => Nextbtn(props.img)}>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
            </Modal.Body>
          </Modal>
        );
    }

    const ImgElement = (props) => {
    return(
        <>
            <span className={`${props.position} cursor-pointer`} >
                <img src={props.img} alt='' className="media" onClick={() => {setModalShow(true); setimgVal(props.img);setimgsize(props.size)}}/>
            </span>
        </>
    )
    }

    return(
        <>
            <div className="container mt-5" id="Awards">
                <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <h2 className="Headers mb-0 fs-lg-3 fs-md-3 fs-sm-2">Awards & Feliciation</h2>
                        <img src={dash}className="mx-auto" alt=""/>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="media-container">
                            <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} img ={imgVal} size={imgsize} />
                            {AwardsArray.map((value,index)=>{                            
                                return(
                                    <ImgElement img={value.img} key={index} size={value.size} position={value.position}/>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Awards;