import Woman1 from '../img/Woman/Woman2.jpg'
import Woman2 from '../img/Woman/Woman1.jpg'
import Woman3 from '../img/Woman/Woman3.jpg'
import Woman4 from '../img/Woman/Woman4.jpg'

const WomanArray=[
    {
        img: Woman1,
        size:'lg',
        position:'big'
    },
    {
        img: Woman2,
        size:'md',
        position:'vertical'
    },
    {
        img: Woman3,
        size:'lg',
        position:'vertical'
    },
    {
        img: Woman4,
        size:'md',
        position:'big'
    },
    
]

export default WomanArray;