import React from "react";
import dash from "../img/dashed.png"
import WomanArray from "../Arrays/WomanArray";



import Modal from 'react-bootstrap/Modal';
import { useState } from 'react'

const Woman = () => {
    const [modalShow, setModalShow] = useState(false);
    const [imgVal, setimgVal] = useState("")
    const [imgsize, setimgsize] = useState("")

    const imgArry = WomanArray.map(media => media.img);
    const sizeArry = WomanArray.map(media => media.size);

    function Nextbtn(img) {
        let arryIndex = imgArry.indexOf(img) + 1
        if (arryIndex === imgArry.length) {
            arryIndex = 0
        }
        setimgsize(sizeArry[arryIndex])
        setimgVal(imgArry[arryIndex])
    }
    function Prevbtn(img) {
        let arryIndex = imgArry.indexOf(img) - 1
        if (arryIndex === -1) {
            arryIndex = imgArry.length - 1
        }
        setimgsize(sizeArry[arryIndex])
        setimgVal(imgArry[arryIndex])
    }

    const MyVerticallyCenteredModal = (props) => {
        return (
            <Modal  {...props} size={props.size} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <button type="button" className="btn-close btn-close-dark position-absolute end-0 top-0 rounded-circle " onClick={props.onHide}>  </button>
                    <img src={props.img} style={{ width: "100%", height: "100%" }} className='p-2' alt="" />

                    <button className="carousel-control-prev h-50 my-auto" type="button" onClick={() => Prevbtn(props.img)}>
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next h-50 my-auto" type="button" onClick={() => Nextbtn(props.img)}>
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </Modal.Body>
            </Modal>
        );
    }

    const ImgElement = (props) => {
        return (
            <>
                <span className={`${props.position} cursor-pointer`} >
                    <img src={props.img} alt='' className="media" onClick={() => { setModalShow(true); setimgVal(props.img); setimgsize(props.size) }} />
                </span>
            </>
        )
    }
    return (
        <>
            <div className="container mt-5" id="WomanTeam">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2 className="Headers mb-0 fs-lg-3 fs-md-3 fs-sm-2">Women's Wheelchair Cricket</h2>
                        <img src={dash} className="mx-auto" alt="" />
                    </div>
                    <div className="row">
                        <div className="col-md-5 my-auto" >
                            <div className="media-container">
                                <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} img={imgVal} size={imgsize} />
                                {WomanArray.map((value, index) => {
                                    return (
                                        <ImgElement img={value.img} key={index} size={value.size} position={value.position} />
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-md-7 my-auto">
                            <p className="text-justify paragraph">As BCCI has taken women's cricket under their wing & started Women's Premier League. We are also in the making of Mumbai Women's Wheelchair Cricket Team. We have taken trials of a few women players & are planning a first ever Women's Wheelchair Cricket match in Mumbai. </p>
                            <p className="text-justify paragraph">Women's wheelchair cricket teams consist of female athletes with physical disabilities who compete in cricket matches while using wheelchairs. These teams are organized at various levels, including local, national, and international, and they participate in tournaments and competitions specifically tailored for wheelchair cricket.</p>
                            <p className="text-justify paragraph">Women's Wheelchair Cricket Team provides a platform for female athletes with disabilities to showcase their talents, compete at a high level, and contribute to the growth and diversity of the sport. It promotes inclusivity and equal opportunities for women in cricket, regardless of their physical abilities.</p>
                            
                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}

export default Woman