import AboutImg1 from '../img/About/about1.png'
// import AboutImg1 from '../img/MainLogo.png'
import AboutImg2 from '../img/About/about2.png'
import AboutImg3 from '../img/About/about5.png'
import AboutImg4 from '../img/About/about4.png'

const AboutArray=[
    {
        img: AboutImg1,
        size:'md',
        position:'big'
    },
    {
        img: AboutImg2,
        size:'md',
        position:'vertical'
    },
    {
        img: AboutImg3,
        size:'md',
        position:'vertical'
    },
    {
        img: AboutImg4,
        size:'md',
        position:'big'
    },
    
]

export default AboutArray;