import dash from "../img/dashed.png"
import international from '../img/Tournaments/international.png'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import national from '../img/Tournaments/National (1).png'

const Tournaments = () => {

    const [InterModal, setInterModal] = useState(false);

    const InterClose = () => setInterModal(false);
    const InterShow = () => setInterModal(true);

    const [NatinalModal, setNatinalModal] = useState(false);

    const NationalClose = () => setNatinalModal(false);
    const NatianlShow = () => setNatinalModal(true);

    return (
        <>
            <div className="container mt-5" id="Tournament">
                <div className="row">
                    <div className="col-md-12 text-center mt-3">
                        <h2 className="Headers mb-0 fs-lg-3 fs-md-3 fs-sm-2">International Tournaments</h2>
                        <img src={dash} className="mx-auto" alt="" />
                    </div>
                    <div className="row my-3">
                        <div className="col-md-5 my-auto cursor-pointer" onClick={InterShow}>
                            <img src={international} alt="" className="w-100 " />
                        </div>
                        <div className="col-md-7 my-auto text-justify paragraph">
                            <div><strong>Won 3-0:</strong> Bilateral Series India vs Bangladesh at Kolkata, West Bangal in May 2023 </div>
                            <div><strong>Runners-up:</strong> Wheelchair Cricket Asia Cup 2019 in Kathmandu, Nepal (Ind, Nep, Ban & Pak) </div>
                            <div><strong>Won 3-0:</strong> Bilateral Series India vs Bangadesh at Mumbai, Maharashtra in Mar 2019</div>
                            <div><strong>Won 2-0:</strong> Bilateral Series India vs Pakistan at Ajman UAE in Sep 2018</div>
                            <div><strong>Runners-up:</strong> Tri-Series India, Nepal & Bangladesh at Rudrapur, Uttarakhand in Apr 2018 </div>
                            <div><strong>Lost 2-1: </strong>Bilateral Series India vs Bangladesh at Mumbai, Maharashtra in Apr 2018</div>
                            <div><strong>3rd Ranked: </strong>Tri-Series India, Nepal & Bangladesh at Kathmandu, Nepal in Dec 2017</div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container mt-5" id="Tournament">
                <div className="row">
                    <div className="col-md-12 text-center mt-3">
                        <h2 className="Headers mb-0 fs-lg-3 fs-md-3 fs-sm-2">National Tournaments</h2>
                        <img src={dash} className="mx-auto" alt="" />
                    </div>
                    <div className="row my-3">
                        <div className="col-md-5 my-auto cursor-pointer text-center" onClick={NatianlShow}>

                            <img src={national} alt="" className="w-100" />
                        </div>
                        <div className="col-md-7 my-auto text-justify paragraph ">

                            <div><strong>5th Ranked: </strong>T-10 National Wheelchaori Cricket Tournament 2023 at Gwalior Madhya Pradesh in May 2023 </div>
                            <div><strong>Runners-up: </strong>Tri-Series (Mumbai, Karnataka & Gujarat) at Mumbai in Feb 2023 </div>
                            <div><strong>6th Ranked: </strong>National Wheelchair Cricket Championship 2022 at Udaipur Rajastan in Dec 2022 </div>
                            <div><strong>6th Ranked: </strong>Zonal Wheelchair Cricket Championship 2022 at Udhaipur, Rajastan in Dec 2022</div>
                            <div><strong>Winner: </strong>Sardar Patel Unitiy Cup 2022 Surat, Gujarat in Oct 2022 </div>
                            <div><strong>6th Ranked: </strong>T-10 National Weelchair Tournament 2022 at Gwalior Madhy pradesh in May 2022</div>
                            <div><strong>Semi-Finalist: </strong>Divyang Wheelchair Cricket Premier League at Ludhiana Punjab in Feb 2021</div>
                            <div><strong>Winner: </strong>Kindness Cup, a state level match at Mumbai in Mar 2020 </div>
                            <div><strong>Winner: </strong>Tri-Series at Raipur Chhattisgarh in jan 2020 </div>
                            <div><strong>Runners-up: </strong>Mangalore Tour vs Karnataka in Dec 2019 </div>
                            <div><strong>Runners-up: </strong>State Lavel Match at Mumbai in Dec 2019 </div>
                            <div><strong>Runners-up: </strong>Indian Wheelchair Premier League 2019 at Dwarka Delhi in Jun 2019 </div>
                            <div><strong>Winners: </strong>National Wheelchair Cricket Match at Bangalore Karnataka in Mar 2019 </div>
                            <div className="w-100"><strong>Winners: </strong>One spacial match on the occasion of World Disablitiy Day at Mumbai, vs Gujarat in Dec 2018</div>
                            <div><strong>Winners: </strong>Indian Wheelchair Premier League 2018 at Delhi in Jun 2018</div>
                            <div><strong>Runners-up: </strong>Surat, Gujarat Tri-Series in May 2018 </div>

                        </div>

                    </div>
                </div>
            </div>

            <Modal show={InterModal} onHide={InterClose} size="lg">
                <Modal.Body >
                    <button type="button" className="btn-close btn-close-dark position-absolute end-0 top-0 rounded-circle " onClick={InterClose}>  </button>
                    <img src={international} alt="" className="w-100" />
                </Modal.Body>
            </Modal>

            <Modal show={NatinalModal} onHide={NationalClose} size="md">
                <Modal.Body >
                    <button type="button" className="btn-close btn-close-dark position-absolute end-0 top-0 rounded-circle " onClick={NationalClose}>  </button>
                    <img src={national} alt="" className="w-100" />
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Tournaments;