import React from "react";
// import video from '../img/Video2.mp4'
import carosel1 from '../img/Image011.png';
import carosel2 from '../img/Image021.png';
import carosel3 from '../img/Image31.png';
import Carousel from 'react-bootstrap/Carousel';

const CaraselImg = () => {
  return (
    <>
        {/* <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" style={{width: '100%'}}>
          <source src={video} type="video/mp4"/>
        </video> */}
        <Carousel slide={false} interval={5000} style={{zIndex:'0'}}>
            <Carousel.Item>
            <img src={carosel1} className="w-100" alt="" />
            </Carousel.Item>
            <Carousel.Item>
                <img src={carosel2} className="w-100" alt="" />
            </Carousel.Item>
            <Carousel.Item>
                <img src={carosel3} className="w-100" alt="" />
            </Carousel.Item>
        </Carousel>
    </>
  );
}

export default CaraselImg;