import preloader from '../img/Preloader_2.gif'
const Loader=()=>{
    return(
        <>
        <div className="loader-container"> 

            <img src={preloader} alt="" />
        </div>

        </>
    )


}
export default Loader;