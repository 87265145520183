import media1 from '../img/MediaCoverage/Media1.png'
import media2 from '../img/MediaCoverage/Media2.png'
import media3 from '../img/MediaCoverage/Media3.png'
import media4 from '../img/MediaCoverage/Media4.png'
import media5 from '../img/MediaCoverage/Media5.png'
import media6 from '../img/MediaCoverage/Media6.png'
import media7 from '../img/MediaCoverage/Media7.png'
import media8 from '../img/MediaCoverage/media8.png'
import media9 from '../img/MediaCoverage/media9.png'
import media10 from '../img/MediaCoverage/media10.png'


const MediaArray=[
    {
        img: media1,
        size:'md',
        position:'vertical'
    },
    {
        img: media2,
        size:'lg',
        position:'big'
    },
    {
        img: media3,
        size:'md',
        position:'big'
    },
    {
        img: media4,
        size:'md',
        position:'vertical'
    },
    {
        img: media5,
        size:'lg',
        position:'horizontal'
    },
    {
        img: media6,
        size:'md',
        position:'vertical'
    },
    {
        img: media7,
        size:'md',
        position:'vertical'
    },
    {
        img: media8,
        size:'md',
        position:'big'
    },
    {
        img: media9,
        size:'md',
        position:'big'
    },
    {
        img: media10,
        size:'lg',
        position:'horizontal'
    },
]

export default MediaArray;