// import { useState } from "react";
import dash from '../img/dashed.png'
import charity from '../img/charity.png'




const Support = () => {
    // const HeadingImage = useContext(HeadImage)



    return (
        <>



            <div className="container wow fadeInDown mt-5" data-wow-duration="1s" data-wow-delay="0.5s" id='Support'>
                <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <h2 className="Headers mb-0 fs-lg-3 fs-md-3 fs-sm-2">Support Us</h2>
                        <img src={dash} className="mx-auto" alt="" />
                    </div>
                </div>
            </div>

            <div className="">

                <div className="container ">
                    <div className="row ">

                        <div className="col-md-6 my-auto ">
                            <p className="text-justify paragraph">Although we are playing Wheelchair Cricket from 2017 at the National & International level, due to lack of support from BCCI & the Government, we don't have a good flow of funds to organize wheelchair cricket matches regularly. Our Wheelchair Cricket has been running on the funds received by NGOs and Voluntary Donors. Supporting wheelchair cricket will promote inclusivity in society via sports & your contribution will make a big differenceto this good cause. We will always remain grateful to you for your kind support. </p>
                            <p className="text-justify paragraph">Donation given to association is exempted for tax benefit under Section 80G of Income Tax. </p>


                        </div>


                        <div className="col-md-6 my-5 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                            <div className="row">
                                <div className="col-md-12 text-center" >
                                    <img src={charity} alt="Charity" className="Charity" />
                                </div>
                                <div className="col-sm-12  ">
                                    <h2 className="Headers text-center"> Bank Details</h2>
                                    <h5><strong>Name:</strong> Wheelchair Sports Association Mumbai</h5>
                                    <h5><strong>Account Number:</strong> 410209100000489</h5>
                                    <h5><strong>IFSC Code:</strong> SRCB0000410</h5>
                                    <h5><strong>Bank Name:</strong> Saraswat Bank</h5>
                                    <h5><strong>Branch:</strong> Santacruz East</h5>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Support;
