import back1 from '../img/CricketerBackground/background1.png'
import back3 from '../img/CricketerBackground/background3.png'
import back4 from '../img/CricketerBackground/background4.png'
import back2 from '../img/CricketerBackground/background2.png'

const BackArray=[
    {
        img: back1,
        size:'md',
        position:'big'
    },
    {
        img: back3,
        size:'md',
        position:'vertical'
    },
    {
        img: back4,
        size:'lg',
        position:'vertical'
    },
    {
        img: back2,
        size:'lg',
        position:'big'
    },
    
]

export default BackArray;