import dash from '../img/dashed.png'



import Modal from 'react-bootstrap/Modal';
import { useState } from 'react'
import BackArray from '../Arrays/BackgroundArray';



const Background = () => {
    const [modalShow, setModalShow] = useState(false);
    const [imgVal, setimgVal] = useState("")
    const [imgsize, setimgsize] = useState("")

    const imgArry = BackArray.map(media => media.img);
    const sizeArry = BackArray.map(media => media.size);

    function Nextbtn(img) {
        let arryIndex = imgArry.indexOf(img) + 1
        if (arryIndex === imgArry.length) {
            arryIndex = 0
        }
        setimgsize(sizeArry[arryIndex])
        setimgVal(imgArry[arryIndex])
    }
    function Prevbtn(img) {
        let arryIndex = imgArry.indexOf(img) - 1
        if (arryIndex === -1) {
            arryIndex = imgArry.length - 1
        }
        setimgsize(sizeArry[arryIndex])
        setimgVal(imgArry[arryIndex])
    }

    const MyVerticallyCenteredModal = (props) => {
        return (
            <Modal  {...props} size={props.size} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <button type="button" className="btn-close btn-close-dark position-absolute end-0 top-0 rounded-circle " onClick={props.onHide}>  </button>
                    <img src={props.img} style={{ width: "100%", height: "100%" }} className='p-2' alt="" />

                    <button className="carousel-control-prev h-50 my-auto" type="button" onClick={() => Prevbtn(props.img)}>
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next h-50 my-auto" type="button" onClick={() => Nextbtn(props.img)}>
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </Modal.Body>
            </Modal>
        );
    }

    const ImgElement = (props) => {
        return (
            <>
                <span className={`${props.position} cursor-pointer`} >
                    <img src={props.img} alt='' className="media" onClick={() => { setModalShow(true); setimgVal(props.img); setimgsize(props.size) }} />
                </span>
            </>
        )
    }

    return (
        <>
            <div className="container mt-5" id="Background">
                <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <h2 className="Headers mb-0 fs-lg-3 fs-md-3 fs-sm-2">Cricketer Background & Career</h2>
                        <img src={dash} className="mx-auto" alt="" />
                    </div>
                    <div className="col-md-7 my-auto">
                        <p className="text-justify back-paragraph">People with disabilities are one of the most invisible communities in India. Due to their disability & mobility barriers, they do not go outside of their houses much. Wheelchair Cricket gives them an opportunity to come out & express themselves. Currently there are 25 persons with disabilities in Mumbai,practising & participating in cricket matches. We are aiming to increase that number to 50-60 persons with disabilities within 2 years. </p>
                        <p className="text-justify back-paragraph"> Wheelchair Cricketers are players with different disabilities like polio,amputation, spinal cord injury & cerebral palsy. Due to the current lack of financial resources, cricket cannot serve as their primary source of income, therefore, they also work. Some players are delivery boys, bike mechanics, mixer mechanics, auto drivers & some run their small shops and other micro businesses. However, this does not stop them from playing & practicing regularly. They carry their wheelchairs & cricket kits on their modified Activas & few of them also travel by train & bus to practice, in spite of the fact that public transport is not wheelchair accessible.</p>
                        <p className="text-justify back-paragraph">
                            Currently, Wheelchair Cricketers are playing this sport as their passion. Cricket is not their prime source of income, they have to work elsewhere. We need proper guidance, professional training & strong financial support, so that players can only focus on their practice & performance. We also want players to take up Wheelchair Cricket as their career so that they can get match fees, daily allowance & travelling allowance. We are trying to create a full eco-system for betterment of Wheelchair Cricket in Mumbai.
                        </p>
                    </div>
                    <div className="col-md-5 my-auto" >
                        <div className="media-container">
                            <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} img={imgVal} size={imgsize} />
                            {BackArray.map((value, index) => {
                                return (
                                    <ImgElement img={value.img} key={index} size={value.size} position={value.position} />
                                )
                            })}
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Background;





