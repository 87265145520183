import { useState } from "react";
// import { HeadImage } from "./MainPage";
// import { Explore,  Mail, Call } from "@material-ui/icons";
// import location from './Images/location.png'
// import locationHov from './Images/locationHov1.png'
import mail from '../img/mail2.png'
import mailHov from '../img/mail1.png'
import calling from '../img/calling.png'
import callingHov from '../img/callingHov.png'
import dash from '../img/dashed.png'
import whatsapp from '../img/Whatsapp.png'
import whatsapp1 from '../img/Whatsapp1.png'
import instaimg from '../img/instagram.png'
import instaimg1 from '../img/instagram1.png'
import faceim1 from '../img/facebook.png'
import faceimg from '../img/facebookHov.png'
import tweetimg from '../img/tweet.png'
import tweetimg1 from '../img/tweet1.png'
// import charity from '../img/charity.png'

// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
// import loadinggif from './Images/LoadigGIF.gif'
// // import { Modal } from "bootstrap";
// import ReactModal from "react-modal"; 



const Contact = () => {
    // const HeadingImage = useContext(HeadImage)

    // const [locationimg,setlocationimg] = useState(location)
    const [mailimg, setmailimg] = useState(mail)
    const [callingimg, setcallingimg] = useState(calling)
    const [Whatsapp, setWhatsapp] = useState(whatsapp)
    const [insta, setInsta] = useState(instaimg1)
    const [Facebook, setFacebook] = useState(faceimg)
    const [tweeter, settweeter] = useState(tweetimg)


    // const LocHov = () => {setlocationimg(locationHov)}
    // const NonLocHov = () => {setlocationimg(location)}
    const MailHov = () => { setmailimg(mailHov) }
    const NonMailHov = () => { setmailimg(mail) }
    const CallHov = () => { setcallingimg(callingHov) }
    const NonCallHov = () => { setcallingimg(calling) }
    const HoverWhatsapp = () => { setWhatsapp(whatsapp1) }
    const NonHoverWhatsapp = () => { setWhatsapp(whatsapp) }
    const HoverInsta = () => { setInsta(instaimg) }
    const NonHoverInsta = () => { setInsta(instaimg1) }
    const HoverFace = () => { setFacebook(faceim1) }
    const NonHoverFace = () => { setFacebook(faceimg) }
    const HoverTweet = () => { settweeter(tweetimg1) }
    const NonHoverTweet = () => { settweeter(tweetimg) }





    return (
        <>
            <div className="container wow fadeInDown mt-5" data-wow-duration="1s" data-wow-delay="0.5s" id='Contact'>
                <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <h2 className="Headers mb-0 fs-lg-3 fs-md-3 fs-sm-2">Get In Touch</h2>
                        <img src={dash} className="mx-auto" alt="" />
                    </div>
                </div>
            </div>



            <div className="container ">
                <div className="row">

                    <div className="col-md-6 my-5 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">

                        <div className="col-md-12 mb-3" onMouseEnter={MailHov} onMouseLeave={NonMailHov}>
                            <input className="mx-1 " type='image' src={mailimg} name="facebook" alt="facebook" width={40} height={40} />
                            <span className="fs-4 fw-bold " style={{ verticalAlign: 'top' }}> Email:</span>
                            <div className="div">
                                <a href="mailto: wheelchairsportsmumbai@gmail.com" style={{ marginLeft: 50, wordWrap: 'break-word' }}>wheelchairsportsmumbai@gmail.com</a>
                            </div>
                        </div>

                        <div className="col-md-12 mb-3" onMouseEnter={CallHov} onMouseLeave={NonCallHov}>
                            <input className="mx-1 " type='image' src={callingimg} name="facebook" alt="facebook" width={40} height={40} />
                            <span className="fs-4 fw-bold" style={{ verticalAlign: 'top' }}> Call:</span>
                            <div className="div">
                                <a className="mx-5 fs-6" href="tel:+917977490455"> +91-7977490455 </a>
                            </div>
                            <div>
                                <a className="mx-5 fs-6" href="tel:+918356984250"> +91-8356984250 </a>
                            </div>
                        </div>

                        <div className="col-md-12 mb-3" onMouseEnter={HoverWhatsapp} onMouseLeave={NonHoverWhatsapp}>
                            <input type='image' src={Whatsapp} width={40} height={40} name="submit" alt="submit" onMouseEnter={HoverWhatsapp} onMouseLeave={NonHoverWhatsapp} />
                            <span className="fs-4 fw-bold " style={{ verticalAlign: 'top' }}> Whatsapp:</span>
                            <div >
                                <a href="https://api.whatsapp.com/send/?phone=9870093594" target='blank' style={{ marginLeft: 50 }}>9870093594</a>
                            </div>
                        </div>



                    </div>

                    <div className="row col-md-6  my-5">

                        <h4 className="Headers">Follow us on: </h4>
                        {/* <div> */}
                        <div className=" mt-2 d-flex ">
                            <a href="https://www.instagram.com/wheelchaircricket.mumbai/" target='blank' >
                                <input type='image' src={insta} width={40} height={40} name="submit" alt="submit" onMouseEnter={HoverInsta} onMouseLeave={NonHoverInsta} />
                            </a>
                            <h5 className="my-1">&nbsp;@wheelchaircricket.mumbai</h5>

                        </div>
                        <div className=" mt-2 d-flex ">
                            <a href="https://www.facebook.com/WCTMumbai" target='blank'>
                                <input type='image' src={Facebook} width={40} height={40} name="submit" alt="submit" onMouseEnter={HoverFace} onMouseLeave={NonHoverFace} />
                            </a>
                            <h5 className="my-1">&nbsp;Mumbai Wheelchair Cricket Team</h5>
                        </div >
                        <div className=" mt-2 d-flex ">
                            <a href="https://twitter.com/MumbaiWCT" target='blank'>
                                <input type='image' src={tweeter} width={40} height={40} name="submit" alt="submit" onMouseEnter={HoverTweet} onMouseLeave={NonHoverTweet} />
                            </a>
                            <h5 className="my-1">&nbsp;MumbaiWCT</h5>
                        </div>
                    </div>


                    {/* <div className="col-md-6 my-5 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div className="row">
                                <div className="col-md-12 text-center" >
                                    <img src={charity} alt="Charity" className="Charity"/>
                                </div>
                                <div className="col-sm-12 ">
                                    <h2 className="Headers text-center"> Bank Details</h2>
                                    <h5><strong>Name:</strong> Wheelchair Sports Association Mumbai</h5>
                                    <h5><strong>Account Number:</strong> 410209100000489</h5>
                                    <h5><strong>IFSC Code:</strong> SRCB0000410</h5>
                                    <h5><strong>Bank Name:</strong> Saraswat Bank</h5>
                                    <h5><strong>Branch:</strong> Santacruz East</h5>

                                </div>
          
                        </div>
                    </div> */}

                </div>
            </div>

        </>
    )
}

export default Contact;
